import { Show } from 'solid-js';
import { useIsEnabled } from '@troon/analytics';
import { NavDotCom } from './nav/dot-com';
import { NavDigital } from './nav/digital';

type Props = {
	hasHero?: boolean;
};

export function Nav(props: Props) {
	const dotComTakeover = useIsEnabled('dot-com-takeover');

	return (
		<Show when={dotComTakeover} fallback={<NavDigital {...props} />}>
			<NavDotCom {...props} />
		</Show>
	);
}
