import { HttpStatusCode } from '@solidjs/start';
import { addBreadcrumb, captureException } from '@sentry/solidstart';
import { createSignal, ErrorBoundary as SolidErrorBoundary, onMount } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Button, Link } from '@troon/ui';
import { twMerge } from '@troon/tailwind-preset/merge';
import { IconSquareWarning } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { getConfigValue } from '../modules/config';
import { getLogger } from '../middleware/logging';
import { Error404 } from '../graphql';
import { BaseLayout } from '../layouts/base';
import { Content } from './content';
import type { ComponentProps, JSX } from 'solid-js';

export function ErrorBoundary(
	props: Omit<ComponentProps<typeof SolidErrorBoundary>, 'fallback'> & { contained?: boolean; content?: JSX.Element },
) {
	const [controllerChanged, setControllerChanged] = createSignal(false);

	onMount(() => {
		if (isServer || !('serviceWorker' in navigator)) {
			return;
		}

		navigator.serviceWorker.addEventListener('controllerchange', (event) => {
			addBreadcrumb({ message: 'ServiceWorker controller change.', timestamp: event.timeStamp });
			setControllerChanged(true);
		});
	});

	return (
		<SolidErrorBoundary
			fallback={(error) => {
				if (error instanceof Error404 || error.name === 'Error404') {
					return (
						<BaseLayout>
							<Content>
								<Title>404 not found | Troon</Title>
								<HttpStatusCode code={404} />
								<h1 class="my-16 text-6xl font-semibold text-brand">Not Found</h1>

								<div class="flex flex-row">
									<div>
										<Button as={Link} href="/">
											Return home
										</Button>
									</div>
								</div>
							</Content>
						</BaseLayout>
					);
				}

				if (controllerChanged() && !isServer) {
					addBreadcrumb({ message: 'Will reload due to ServiceWorker controller change.' });
					captureException(error);
					window.location.reload();
					return null;
				}

				if (isServer) {
					getLogger().error(error);
				}

				captureException(error);

				const content = props.content ?? (
					<>
						<h1
							class={twMerge(
								'flex flex-row items-center gap-2 font-semibold text-red-500',
								props.contained ? 'my-16 text-6xl' : 'my-8 text-4xl',
							)}
						>
							<IconSquareWarning />
							An error has occured
						</h1>
						<p class="mb-4">Something went wrong.</p>
						{getConfigValue('ENVIRONMENT', 'development') === 'development' ? (
							<pre class="mb-4 overflow-x-auto rounded-md bg-brand-900 p-4 font-mono text-white">
								{(error as Error).stack ?? error.toString()}
							</pre>
						) : null}
						<div class="flex flex-row">
							<div class="flex flex-row gap-4">
								<Button
									onClick={() => {
										window.location.reload();
									}}
								>
									Reload
								</Button>
								<Button appearance="secondary" as={Link} href="/" target="_self">
									Go home
								</Button>
							</div>
						</div>
					</>
				);

				return props.contained ? <Content>{content}</Content> : content;
			}}
		>
			{props.children}
		</SolidErrorBoundary>
	);
}
