import { Match, Suspense, Switch, For, Show, createSignal, createUniqueId, createEffect } from 'solid-js';
import {
	Avatar,
	DialogContent,
	Dialog,
	Logo,
	LinkButton,
	NavigationMenu,
	NavigationMenuItem,
	Button,
	Tag,
	Link,
	Picture,
} from '@troon/ui';
import {
	IconBellRing,
	IconCalendar,
	IconCaretDownMd,
	IconChevronRight,
	IconCloseMd,
	IconHamburgerMd,
	IconLogOut,
	IconMap,
	IconSearchMagnifyingGlass,
	IconStar,
	IconTag,
	IconUserCircle,
	IconUserSquare,
} from '@troon/icons';
import { createIntersectionObserver } from '@solid-primitives/intersection-observer';
import { NavigationMenu as KNavigationMenu } from '@kobalte/core/navigation-menu';
import { Accordion as KAccordion } from '@kobalte/core/accordion';
import { Dialog as KDialog } from '@kobalte/core/dialog';
import { Dynamic } from 'solid-js/web';
import { createStore, produce } from 'solid-js/store';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useUser } from '../../providers/user';
import { AuthFlow } from '../../partials/auth/auth';
import { Content } from '../content';
import { getConfigValue } from '../../modules/config';
import type { Component, ComponentProps, ParentProps } from 'solid-js';
import type { IconProps } from '@troon/icons';

const userNav: Array<ComponentProps<typeof NavigationMenuItem>> = [
	{ icon: IconUserSquare, href: '/account', children: 'My account' },
	{ icon: IconCalendar, href: '/reservations', children: 'Reservations' },
	{ icon: IconBellRing, href: '/alerts', children: 'Tee time alerts' },
	{ icon: IconStar, href: '/courses/favorites', children: 'Favorites' },
];

type Props = {
	hasHero?: boolean;
};

export function NavDotCom(props: Props) {
	const user = useUser();
	const [authOpen, setAuthOpen] = createSignal(false);
	const authDialogId = createUniqueId();
	const [targets, setTargets] = createSignal<Array<Element>>([]);
	const [menuOpen, setMenuOpen] = createSignal(false);
	const menuId = createUniqueId();
	const [menuStore, setMenuStore] = createStore({
		useHeroMode: props.hasHero,
		atTop: true,
		dialog: false,
		menus: navItems.reduce<Record<string, boolean>>((memo, item) => {
			memo[item.title] = false;
			return memo;
		}, {}),
	});

	let nav: HTMLDivElement;

	createEffect(() => {
		const menuOpen = Object.values({ ...menuStore.menus }).some((v) => !!v);
		setMenuStore('useHeroMode', !menuStore.dialog && !menuOpen && props.hasHero && menuStore.atTop);
	});

	createIntersectionObserver(targets, (entries) => {
		if (!props.hasHero) {
			return;
		}
		setMenuStore(
			'atTop',
			entries.some((entry) => entry.isIntersecting),
		);
	});

	return (
		<div
			// Account for the fixed position child
			class="h-16"
		>
			<div ref={(el) => setTargets([el])} class="pointer-events-none absolute inset-x-0 top-0 h-12" />
			<div ref={nav!} class="fixed inset-x-0 top-0 z-40">
				<nav
					class="h-16 border-b transition-colors duration-100"
					classList={{
						'border-b-white/20 bg-white/0 text-white': menuStore.useHeroMode,
						'border-b-neutral-500 bg-white text-neutral-900': !menuStore.useHeroMode,
					}}
				>
					<Content class="relative flex h-full items-center justify-stretch gap-x-4">
						<KDialog
							modal
							onOpenChange={(open) => {
								setMenuStore('dialog', open);
								if (!open) {
									setMenuStore(
										produce((s) => {
											for (const key of Object.keys(s.menus)) {
												s.menus[key] = false;
											}
										}),
									);
								}
							}}
						>
							<KDialog.Trigger
								as={Button}
								aria-haspopup="dialog"
								aria-expanded={menuOpen()}
								aria-controls={menuOpen() ? menuId : undefined}
								onClick={() => setMenuOpen(true)}
								appearance="transparent-current"
								size="sm"
								class="shrink grow-0 rounded-md px-2 py-1 text-xl xl:hidden"
							>
								<Show when={menuStore.dialog} fallback={<IconHamburgerMd class="shrink-0" />}>
									<IconCloseMd class="shrink-0" />
								</Show>
							</KDialog.Trigger>
							<KDialog.Portal>
								<KDialog.Content class="fixed inset-0 top-16 z-40 flex flex-col justify-between overflow-hidden bg-white animate-out fade-out slide-out-to-bottom anim-duration-200 ui-expanded:duration-700 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-bottom">
									<KDialog.Title class="sr-only">Site navigation</KDialog.Title>
									<div class="grow overflow-x-auto">
										<KAccordion
											as="nav"
											collapsible
											onChange={(value) => {
												const visible = value[0];
												setMenuStore(
													produce((s) => {
														const menu: Record<string, boolean> = {};
														for (const title of Object.keys(s.menus)) {
															menu[title] = title === visible;
														}
														s.menus = menu;
													}),
												);
											}}
										>
											<For each={navItems}>
												{(item) => (
													<Show
														when={item.items}
														fallback={
															<Link
																href={item.href}
																class="flex w-full items-center justify-between rounded-none border-b border-s-4 border-transparent border-b-neutral px-4 py-6 transition-colors duration-200"
															>
																<span>{item.title}</span>
																<Show when={item.tag}>
																	<Tag>{item.tag}</Tag>
																</Show>
															</Link>
														}
													>
														<KAccordion.Item value={item.title} class="border-b border-neutral">
															<KAccordion.Header>
																<KAccordion.Trigger class="group flex w-full items-center justify-between border-s-4 border-transparent px-4 py-6 transition-colors duration-200 ui-expanded:border-brand ui-expanded:bg-brand-100">
																	<span>{item.title}</span>
																	<Show when={item.tag}>
																		<Tag>{item.tag}</Tag>
																	</Show>
																	<Show when={item.items}>
																		<IconCaretDownMd
																			class={twJoin(
																				'transition-all duration-200',
																				menuStore.menus[item.title] && 'rotate-180 text-brand',
																			)}
																		/>
																	</Show>
																</KAccordion.Trigger>
															</KAccordion.Header>

															<KAccordion.Content
																// eslint-disable-next-line tailwindcss/no-arbitrary-value
																class="animate-[accordionOut_200ms_ease-in-out] overflow-clip border-s-4 border-transparent ui-expanded:animate-[accordionIn_200ms_ease-in-out]"
															>
																<Show when={'items' in item && item.items}>
																	{(subItems) => (
																		<ul class="flex flex-col gap-2">
																			<For each={subItems()}>
																				{(subItem) => (
																					<Show when={!subItem.grid}>
																						<li>
																							<Link
																								href={subItem.href}
																								class="flex h-full grow gap-2 overflow-hidden rounded-none p-4 outline-none hover:bg-brand-100 focus-visible:bg-brand-100 focus-visible:ring-0"
																							>
																								<Show when={subItem.icon}>
																									{(icon) => (
																										<Dynamic
																											component={icon()}
																											class="z-10 shrink-0 grow-0 text-lg text-brand"
																										/>
																									)}
																								</Show>
																								<span class={twJoin('z-10 flex h-full grow flex-col gap-1')}>
																									<span class="font-semibold capitalize">{subItem.title}</span>
																									<Show when={subItem.description}>
																										{(desc) => <span class="text-sm text-neutral-800">{desc()}</span>}
																									</Show>
																								</span>{' '}
																							</Link>
																						</li>
																					</Show>
																				)}
																			</For>
																		</ul>
																	)}
																</Show>
															</KAccordion.Content>
														</KAccordion.Item>
													</Show>
												)}
											</For>
										</KAccordion>
									</div>
									<Show when={!user()}>
										<ul class="flex items-center justify-center gap-4 border-t border-neutral py-4 leading-3">
											<li class="border-r border-r-neutral pe-4">
												<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
													<span class="text-neutral-950">Sign up</span>
												</AuthButton>
											</li>
											<li>
												<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
													<IconUserCircle class="-my-1 text-xl text-brand" />
													<span class="text-neutral-950">Log in</span>
												</AuthButton>
											</li>
										</ul>
									</Show>
								</KDialog.Content>
							</KDialog.Portal>
						</KDialog>

						<div
							class="flex grow items-center justify-center sm:justify-start xl:grow-0"
							classList={{
								'text-neutral-900': !menuStore.useHeroMode,
							}}
						>
							<LinkButton appearance="current" href="/" size="sm">
								<Logo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</LinkButton>
						</div>

						<div class="hidden grow xl:block">
							<KNavigationMenu class="flex gap-2" delayDuration={0} skipDelayDuration={300} gutter={16}>
								<For each={navItems}>
									{(item) => (
										<KNavigationMenu.Menu
											modal={false}
											preventScroll
											onOpenChange={(open) => {
												setMenuStore('menus', item.title, open);
											}}
										>
											<KNavigationMenu.Trigger
												as={LinkButton}
												appearance="current"
												href={item.href}
												class="appearance-none capitalize focus-visible:bg-current/10 focus-visible:ring-0"
											>
												{item.title}
												<Show when={item.tag}>
													<Tag>{item.tag}</Tag>
												</Show>
												<Show when={'items' in item}>
													<KNavigationMenu.Icon class="transition-transform duration-200 ui-expanded:rotate-180">
														<IconCaretDownMd />
													</KNavigationMenu.Icon>
												</Show>
											</KNavigationMenu.Trigger>
											<Show
												when={'items' in item && item.items}
												fallback={
													<KNavigationMenu.Portal>
														<KNavigationMenu.Content class="absolute h-px overflow-hidden bg-neutral-500 opacity-0" />
													</KNavigationMenu.Portal>
												}
											>
												{(subItems) => (
													<KNavigationMenu.Portal>
														<KNavigationMenu.Content
															class={twJoin(
																'container pointer-events-none absolute inset-x-0 top-0 mx-auto grid w-full origin-left grid-flow-row grid-cols-3 gap-x-24 gap-y-4 overflow-y-hidden px-4 py-11 outline-none ease-in-out anim-duration-200 ui-enter:animate-in ui-enter:fade-in ui-exit:animate-out ui-exit:fade-out ui-enter-start:slide-in-from-left ui-enter-end:slide-in-from-right ui-exit-start:slide-out-to-left ui-exit-end:slide-out-to-right ui-expanded:pointer-events-auto sm:px-6 md:px-12',
																...subItems()
																	.filter((subItem) => subItem.grid)
																	.map(({ grid }) => grid),
															)}
														>
															<For each={subItems()}>{(subItem) => <NavigationItem item={subItem} />}</For>
														</KNavigationMenu.Content>
													</KNavigationMenu.Portal>
												)}
											</Show>
										</KNavigationMenu.Menu>
									)}
								</For>
								<KNavigationMenu.Viewport
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="z-50 -ms-2 flex w-screen origin-[var(--kb-menu-content-transform-origin)] items-center justify-center overflow-clip border-b border-neutral bg-transparent text-neutral-950 shadow-lg outline-none transition-all duration-200 ease-in animate-out fade-out slide-out-to-top-32 anim-duration-200 fill-mode-forwards ui-expanded:h-[var(--kb-navigation-menu-viewport-height)] ui-expanded:bg-white ui-expanded:opacity-100 ui-expanded:ease-out ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-top ui-expanded:fill-mode-none"
								/>
							</KNavigationMenu>
						</div>

						{/* Profile dropdown */}
						<div
							class={twJoin(
								'flex shrink grow-0 flex-row items-center transition-opacity duration-200 xl:ml-3',
								menuStore.dialog && 'pointer-events-none opacity-0',
							)}
						>
							<Suspense>
								<Switch>
									<Match when={user()}>
										{(user) => (
											<ul class="flex items-center gap-4 leading-3">
												<li class="hidden border-r border-r-current pe-4 sm:block">
													<LinkButton href="/reservations" appearance="current">
														<IconCalendar
															class="-my-1 text-xl"
															classList={{
																'text-current': menuStore.useHeroMode,
																'text-brand': !menuStore.useHeroMode,
															}}
														/>
														<span class="sr-only sm:not-sr-only">My reservations</span>
													</LinkButton>
												</li>
												<li>
													<NavigationMenu
														showIcon={false}
														sameWidth={false}
														trigger={() => (
															<LinkButton
																href="/account"
																appearance="current"
																onClick={(e) => e.preventDefault()}
																size="sm"
															>
																<Avatar
																	firstName={user().me.firstName}
																	lastName={user().me.lastName}
																	aria-label="Open user menu"
																	class="size-8"
																	classList={{
																		'text-current': menuStore.useHeroMode,
																		'text-brand': !menuStore.useHeroMode,
																	}}
																/>
																<IconCaretDownMd />
															</LinkButton>
														)}
													>
														<KNavigationMenu.Item
															as={Link}
															href="/account"
															class="relative -mx-3 -mt-3 flex flex-col items-center gap-6 rounded-b-none rounded-t bg-gradient-to-r from-neutral-950 to-brand-700 px-3 py-6 text-center text-white"
														>
															<img
																src="/assets/images/topo-bg-bottom.svg"
																alt=""
																class="absolute inset-x-0 bottom-0"
																loading="lazy"
															/>

															<div class="flex flex-col items-center gap-1">
																<Avatar
																	firstName={user().me.firstName}
																	lastName={user().me.lastName}
																	class="mb-2 size-16"
																/>
																<span>
																	{user().me.firstName} {user().me.lastName}
																</span>
																<span class="text-sm">{user().me.email}</span>
															</div>
															<span>
																View account <IconChevronRight />
															</span>
														</KNavigationMenu.Item>
														<For each={userNav}>{(item) => <NavigationMenuItem {...item} />}</For>
														<NavigationMenuItem icon={IconLogOut} href="/auth/logout">
															Log out
														</NavigationMenuItem>
													</NavigationMenu>
												</li>
											</ul>
										)}
									</Match>

									<Match when={user() === null}>
										<>
											<ul class="flex items-center gap-4 leading-3">
												<li class="hidden border-r border-r-current pe-4 sm:block">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														Sign up
													</AuthButton>
												</li>
												<li>
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														<IconUserCircle
															class="-my-1 text-xl"
															classList={{
																'text-current': menuStore.useHeroMode,
																'text-brand': !menuStore.useHeroMode,
															}}
														/>
														<span class="sr-only sm:not-sr-only">Log in</span>
													</AuthButton>
												</li>
											</ul>
											<Dialog key="auth-nav" open={authOpen()} onOpenChange={setAuthOpen} id={authDialogId}>
												<DialogContent autoWidth noPadding noClose floatingClose>
													<AuthFlow onComplete={() => setAuthOpen(false)} />
												</DialogContent>
											</Dialog>
										</>
									</Match>
								</Switch>
							</Suspense>
						</div>
					</Content>
				</nav>
			</div>
		</div>
	);
}

function AuthButton(props: ParentProps<{ open: boolean; id: string; setOpen: (o: boolean) => void }>) {
	return (
		<LinkButton
			aria-haspopup="dialog"
			aria-expanded={props.open}
			aria-controls={props.open ? `${props.id}-content` : undefined}
			onClick={(e) => {
				e.preventDefault();
				props.setOpen(true);
			}}
			appearance="current"
			href="/auth"
		>
			{props.children}
		</LinkButton>
	);
}

function NavigationItem(props: { item: NavItem }) {
	return (
		<KNavigationMenu.Item
			as={Link}
			href={props.item.href}
			class={twJoin(
				'group/item relative flex h-full grow gap-2 overflow-hidden rounded p-4 hover:bg-brand-100 focus-visible:bg-brand-100 focus-visible:ring-0',
				props.item.img && 'text-white',
			)}
		>
			<Show when={props.item.img}>
				{(img) => (
					<>
						<Picture
							src={img()}
							sizes={[[640, 480]]}
							alt=""
							class="absolute inset-0 -z-0 size-full object-cover transition-transform duration-150 group-hover/item:scale-105"
						/>
						<span class="absolute inset-0 z-0 bg-gradient-to-t from-black/80 to-black/0" />
					</>
				)}
			</Show>
			<Show when={props.item.icon}>
				{(icon) => <Dynamic component={icon()} class="z-10 shrink-0 grow-0 text-lg text-brand" />}
			</Show>
			<span class={twJoin('z-10 flex h-full grow flex-col gap-1', props.item.img && 'justify-end')}>
				<KNavigationMenu.ItemLabel class="font-semibold capitalize">{props.item.title}</KNavigationMenu.ItemLabel>
				<Show when={props.item.description}>
					{(desc) => (
						<KNavigationMenu.ItemDescription class={twJoin('text-sm', !props.item.img && 'text-neutral-800')}>
							{desc()}
						</KNavigationMenu.ItemDescription>
					)}
				</Show>
			</span>
		</KNavigationMenu.Item>
	);
}

type NavItem = {
	title: string;
	href: string;
	description?: string;
	icon?: Component<IconProps>;
	img?: string;
	grid?: string;
};

type NavSection = {
	title: string;
	href: string;
	tag?: string;
	items?: Array<NavItem>;
};

const navItems: Array<NavSection> = [
	{
		title: 'Play Troon',
		href: '/tee-times',
		items: [
			{
				title: 'Find a tee time',
				description: 'Compare tee times and always find the best rate to play.',
				icon: IconSearchMagnifyingGlass,
				href: '/tee-times',
			},
			{
				title: 'Course directory',
				description: 'Browse through our network of over 900 courses.',
				icon: IconMap,
				href: '/courses',
			},
			{
				title: 'Troon Access',
				description: 'Unlock exclusive savings at over 150 participating Troon courses.',
				href: '/access',
				img: `${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`,
				grid: '[&>*:nth-child(3)]:row-span-3',
			},
			{
				title: 'Troon Rewards',
				description: 'Earn points with every round you play to unlock complimentary rounds.',
				icon: IconStar,
				href: '/',
			},
			{
				title: 'Troon Privé',
				description: 'Extraordinary golf and lifestyle benefits for private club members.',
				icon: IconTag,
				href: '/member-programs',
			},
		],
	},
	{
		title: 'Troon Access',
		href: '/access',
		tag: 'New',
	},
	{
		title: 'Management services',
		href: '/management-services',
		items: [
			{
				title: 'Management services',
				description:
					'Troon® started as one facility in 1990 and has since grown to become the world’s largest professional club management company. Today, we provide services to 900+ locations and manage various amenities, such as racquet sports, aquatics, fitness, food & beverage, lodging, homeowners associations, and more.',
				href: '/management-services',
				grid: '[&>*:nth-child(1)]:row-span-3',
			},
			{
				title: 'Private clubs',
				description: 'Enhance your private club member experience',
				href: '/management-services/private-clubs',
			},
			{
				title: 'Community Associations',
				description: 'Integrate a hospitality-centric approach to your community',
				href: '/management-services/community-associations',
			},
			{
				title: 'Daily fee & Resort',
				description: 'Enhance your guest experience experience and grow your clubs revenue',
				href: '/management-services/daily-fee',
			},
			{
				title: 'Advisory services',
				description: 'An alternate to professional management solutions',
				href: '/management-services/advisory-services',
			},
			{
				title: 'Municipal properties',
				description: 'Improve your course conditions and reduce your costs',
				href: '/management-services/municipal-properties',
			},
			{
				title: 'Troon Partners Network',
				description: 'Establish a brand identity among golf’s most influential brands',
				href: '/management-services/troon-partners-network',
			},
		],
	},
	{
		title: 'About us',
		href: '/about',
		items: [
			{
				title: 'About Troon',
				description:
					'Troon started as one facility in 1990 and has since grown to become the world’s largest professional club management company. ',
				href: '/about',
			},
			{
				title: 'Meet the team',
				description: 'Lorem ipsum dolor sit amet consectetur. Pellentesque fusce egestas.',
				href: '/about/team',
			},
			{
				title: 'Join our team!',
				description: 'Enhance your guest experience experience and grow your clubs revenue',
				href: '/about/career-opportunities',
				img: `${getConfigValue('IMAGE_HOST')}/digital/hero/career.jpeg`,
				grid: '[&>*:nth-child(3)]:row-span-3',
			},
			{
				title: 'Career opportunities',
				description: 'Enhance your guest experience experience and grow your clubs revenue',
				href: '/about/career-opportunities',
			},
			{
				title: 'Contact us',
				description: 'Have a question? Get in touch',
				href: '/about/contact',
			},
			{
				title: 'Diversity, Equity and Inclusion',
				description: 'Integrate a hospitality-centric approach to your community',
				href: '/about/dei',
			},
		],
	},
];
