import { Match, Suspense, Switch, For, Show, createSignal, createUniqueId } from 'solid-js';
import { Avatar, DialogContent, Dialog, Logo, LinkButton, NavigationMenu, NavigationMenuItem } from '@troon/ui';
import {
	IconBellRing,
	IconCalendar,
	IconHamburgerMd,
	IconHouse01,
	IconMobileButton,
	IconStar,
	IconTag,
	IconUserCircle,
	IconUserSquare,
} from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { createIntersectionObserver } from '@solid-primitives/intersection-observer';
import { useUser } from '../../providers/user';
import { AuthFlow } from '../../partials/auth/auth';
import { createNumberFormatter } from '../../modules/number-formatting';
import type { ComponentProps, ParentProps } from 'solid-js';

const userNav: Array<ComponentProps<typeof NavigationMenuItem>> = [
	{ icon: IconUserSquare, href: '/account', children: 'My account' },
	{ icon: IconCalendar, href: '/reservations', children: 'Reservations' },
	{ icon: IconBellRing, href: '/alerts', children: 'Tee time alerts' },
	{ icon: IconStar, href: '/courses/favorites', children: 'Favorites' },
];

type Props = {
	hasHero?: boolean;
};

export function NavDigital(props: Props) {
	const user = useUser();
	const [authOpen, setAuthOpen] = createSignal(false);
	const authDialogId = createUniqueId();
	const [targets, setTargets] = createSignal<Array<Element>>([]);

	let nav: HTMLDivElement;

	const numberFormatter = createNumberFormatter();

	createIntersectionObserver(targets, (entries) => {
		if (!props.hasHero) {
			return;
		}
		if (entries.some((entry) => entry.isIntersecting)) {
			nav?.classList.add('with-hero');
		} else {
			nav?.classList.remove('with-hero');
		}
	});

	return (
		<>
			<div ref={(el) => setTargets([el])} class="pointer-events-none absolute inset-x-0 top-0 h-12" />
			<div
				ref={nav!}
				// eslint-disable-next-line tailwindcss/no-custom-classname
				class={twJoin('group sticky inset-x-0 top-0 z-40', props.hasHero && 'with-hero')}
				/**
				 * Important! Use classList here because it's faster and more correct at applying the class names
				 * when navigating and avoiding major re-renders.
				 */
				classList={{ 'with-hero': props.hasHero }}
			>
				<nav
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="h-16 border-b transition-colors duration-200 group-[.with-hero]:border-b-white/20 group-[:not(.with-hero)]:border-b-neutral-500 group-[.with-hero]:bg-transparent group-[:not(.with-hero)]:bg-white group-[.with-hero]:text-white group-[:not(.with-hero)]:text-brand"
				>
					<div class="relative flex h-full items-center justify-stretch gap-x-4 px-4 sm:px-6 lg:px-8">
						<Suspense>
							<Show when={user()?.me}>
								{(me) => (
									<div class="shrink grow-0 lg:hidden">
										<NavigationMenu
											showIcon={false}
											sameWidth={false}
											trigger={() => (
												<>
													<IconHamburgerMd class="text-xl" />
													<span class="sr-only">Menu</span>
												</>
											)}
										>
											<NavigationMenuItem icon={IconHouse01} href="/">
												Home
											</NavigationMenuItem>

											<Show when={me().card?.id}>
												{(cardId) => (
													<NavigationMenuItem icon={IconTag} href={`/card/${cardId()}`}>
														Troon Card booking
													</NavigationMenuItem>
												)}
											</Show>

											<NavigationMenuItem icon={IconCalendar} href="/reservations">
												My reservations
											</NavigationMenuItem>
										</NavigationMenu>
									</div>
								)}
							</Show>
						</Suspense>

						<div
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class={twJoin(
								user() ? 'grow text-center lg:grow-0' : 'flex grow justify-items-start',
								'group-[:not(.with-hero)]:text-neutral-900',
							)}
						>
							<LinkButton appearance="current" href="/" size="sm">
								<Logo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</LinkButton>
						</div>

						<div class="hidden grow lg:block">
							<Suspense>
								<Show when={user()?.me}>
									{(me) => (
										<ul
											// eslint-disable-next-line tailwindcss/no-arbitrary-value
											class="hidden flex-row justify-start gap-4 group-[.with-hero]:text-white group-[:not(.with-hero)]:text-neutral-900 lg:flex"
										>
											<li>
												<LinkButton appearance="current" href="/" size="sm" class="text-base">
													Home
												</LinkButton>
											</li>
											<Show when={me().card?.id}>
												{(cardId) => (
													<li>
														<LinkButton appearance="current" href={`/card/${cardId()}`} size="sm" class="text-base">
															Troon Card booking
														</LinkButton>
													</li>
												)}
											</Show>
											<li>
												<LinkButton appearance="current" href="/reservations" size="sm" class="text-base">
													My reservations
												</LinkButton>
											</li>
										</ul>
									)}
								</Show>
							</Suspense>
						</div>

						{/* Profile dropdown */}
						<div
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="flex shrink grow-0 flex-row items-center group-[.with-hero]:text-white group-[:not(.with-hero)]:text-current lg:ml-3"
						>
							<Suspense>
								<Switch>
									<Match when={user()}>
										{(user) => (
											<>
												<div class="me-2 hidden border-r border-current pe-2 lg:block">
													<LinkButton href="/app" target="_blank" appearance="current">
														<IconMobileButton /> Download app
													</LinkButton>
												</div>
												<div>
													<NavigationMenu
														showIcon={false}
														sameWidth={false}
														trigger={() => (
															<div class="flex items-center gap-2 rounded-md p-1 hover:bg-current/10">
																<Avatar
																	firstName={user().me.firstName}
																	lastName={user().me.lastName}
																	aria-label="Open user menu"
																	class="size-8"
																/>{' '}
																<span class="hidden text-nowrap font-normal lg:block">
																	{numberFormatter().format(user().userRewards?.availablePoints)}{' '}
																	<abbr title="Points" class="no-underline">
																		pts
																	</abbr>
																</span>
															</div>
														)}
													>
														<For each={userNav}>{(item) => <NavigationMenuItem {...item} />}</For>
														<NavigationMenuItem href="/auth/logout">Log out</NavigationMenuItem>
													</NavigationMenu>
												</div>
											</>
										)}
									</Match>

									<Match when={!user()}>
										<>
											<ul class="flex items-baseline gap-4 text-sm leading-3">
												<li class="block sm:hidden">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														<span class="text-2xl leading-0">
															<IconUserCircle title="Sign in" />
														</span>
													</AuthButton>
												</li>
												<li class="hidden border-r border-r-current pe-4 sm:block">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														Join Troon Rewards
													</AuthButton>
												</li>
												<li class="hidden sm:block">
													<AuthButton open={authOpen()} setOpen={setAuthOpen} id={authDialogId}>
														Sign in{' '}
														<span class="-my-2 inline-block text-xl leading-0">
															<IconUserCircle />
														</span>
													</AuthButton>
												</li>
											</ul>
											<Dialog key="auth-nav" open={authOpen()} onOpenChange={setAuthOpen} id={authDialogId}>
												<DialogContent autoWidth noPadding noClose floatingClose>
													<AuthFlow onComplete={() => setAuthOpen(false)} />
												</DialogContent>
											</Dialog>
										</>
									</Match>
								</Switch>
							</Suspense>
						</div>
					</div>
				</nav>
			</div>
		</>
	);
}

function AuthButton(props: ParentProps<{ open: boolean; id: string; setOpen: (o: boolean) => void }>) {
	return (
		<LinkButton
			aria-haspopup="dialog"
			aria-expanded={props.open}
			aria-controls={props.open ? `${props.id}-content` : undefined}
			onClick={(e) => {
				e.preventDefault();
				props.setOpen(true);
			}}
			appearance="current"
			href="/auth"
			size="sm"
			class="font-semibold uppercase"
		>
			{props.children}
		</LinkButton>
	);
}
