import { Suspense, Switch, Match, createMemo, For } from 'solid-js';
import { Logo, LinkButton } from '@troon/ui';
import { useLocation, useSearchParams } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Link } from '@solidjs/meta';
import { Nav } from '../components/nav';
import { Hero } from '../components/hero';
import { Footer } from '../components/footer';
import { useUser } from '../providers/user';
import { Content } from '../components/content';
import { SupportButton } from '../components/support';
import { getBaseUrl } from '../modules/schema/base-url';
import type { ParentProps, JSX } from 'solid-js';

type Props = ParentProps<{
	banner?: () => JSX.Element;
	hero?: string;
	heroElement?: () => JSX.Element;
	heroContent?: () => JSX.Element;
	heroSize?: 'default' | 'large';
	nav?: 'default' | 'none' | 'splash' | 'minimal';
	minimalNav?: boolean;
}>;

export function BaseLayout(props: Props) {
	const location = useLocation();
	const [params] = useSearchParams();
	const user = useUser();

	const url = createMemo(() => {
		const withTrailing = new URL(
			location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`,
			getBaseUrl(),
		);
		const withoutTrailing = new URL(location.pathname.replace(/\/$/, ''), getBaseUrl());
		for (const [key, val] of Object.entries(params)) {
			if (typeof val !== 'undefined') {
				withTrailing.searchParams.append(key, val);
				withoutTrailing.searchParams.append(key, val);
			}
		}
		if (withTrailing.pathname === withoutTrailing.pathname) {
			return [withTrailing];
		}
		return [withTrailing, withoutTrailing];
	});

	return (
		<>
			<For each={url()}>{(url) => <Link rel="canonical" href={url.toString()} />}</For>

			<div class="flex min-h-screen w-screen flex-col justify-stretch">
				<Switch>
					<Match when={props.nav === 'splash'}>
						<nav class="absolute inset-x-0 top-0 z-40 flex h-16 flex-row items-center justify-center border-b border-b-white/20 bg-transparent text-white">
							<LinkButton appearance="current" href="/" size="sm">
								<Logo class="w-28" aria-label="Troon logo" />
								<span class="sr-only">Troon home</span>
							</LinkButton>
						</nav>
					</Match>
					<Match when={props.nav === 'minimal'}>
						<nav class="h-16 border-b border-b-neutral-500 bg-white">
							<Content class="flex h-full items-center justify-between gap-x-4 sm:px-6 lg:px-8">
								<LinkButton appearance="current" href="/" size="sm">
									<Logo class="w-28" aria-label="Troon logo" />
									<span class="sr-only">Troon home</span>
								</LinkButton>
								<div>
									<SupportButton appearance="transparent-current" size="sm" class="font-normal normal-case">
										Need help?
									</SupportButton>
								</div>
							</Content>
						</nav>
					</Match>
					<Match when={props.nav !== 'none'}>
						<Suspense>
							<Nav hasHero={!!props.heroElement || Boolean(props.hero)} />
							<Switch>
								<Match when={props.hero}>
									<Hero src={props.hero} size={props.heroSize}>
										{props.heroContent ? props.heroContent() : null}
									</Hero>
								</Match>
								<Match when={props.heroElement}>
									{(element) => <div class="relative -top-16 -mb-24">{element()()}</div>}
								</Match>
							</Switch>
						</Suspense>
					</Match>
				</Switch>
				<main id="content" class={twJoin('flex shrink-0 grow flex-col', props.nav !== 'none' && 'pb-6 pt-8 md:py-12')}>
					{props.children}
				</main>
				<footer class={twJoin('bg-white', user() ? 'shrink-0 pb-24 md:pb-4' : 'shrink-0 pb-4')}>
					<Footer />
				</footer>
			</div>
		</>
	);
}
